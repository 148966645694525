npm
<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <h1>Designed and Developed by:</h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col class="shrink">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              href="https://enrag.at"
              icon
              large
              target="_blank"
              v-on="on"
            >
              <img
                class="ml-2"
                :src="require('@/assets/logo_enrag.svg')"
                :height="150"
              />
            </v-btn>
          </template>
          <span>Visit Us!</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  computed: {},
};
</script>

<style></style>
